







import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import echarts from 'echarts'
@Component
// @ts-ignore
export default class Statistics extends Vue {
	pagerHeader: any = {
		title: '财务统计',
		desc: '财务统计',
		breadcrumb: ['财务统计', '财务管理'],
	}
	mounted() {
		this.drawLine()
	}
	async drawLine() {
		// 基于准备好的dom，初始化echarts实例
        // @ts-ignore
		let myChart = await this.$echarts.init(
			document.getElementById('myChart')
		)
		// 绘制图表
		myChart.setOption({
			title: { text: '财务统计' },
			tooltip: {},
			xAxis: {
				type: 'category',
				boundaryGap: false,
				data: [
					'2020/7/4',
					'2020/7/5',
					'2020/7/6',
					'2020/7/7',
					'2020/7/8',
					'2020/7/9',
					'2020/7/10',
				],
			},
			yAxis: {
				type: 'value',
			},
			series: [
				{
					data: [0, 600, 901, 934, 1290, 1330, 1500],
					type: 'line',
					areaStyle: {
						color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
							{
								offset: 0,
								color: '#54E8ED',
							},
							{
								offset: 1,
								color: '##DCFAFB',
							},
						]),
					},
				},
			],
		})
	}
}
